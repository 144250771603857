import { userViewBidsRequest } from "src/types/userViewBids";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Product } from "src/types/product";
import { toast } from "src/components/ui";
import { API_URL } from "src/config/environmentvariables";

/**Dummy url for api demo purposes */
export const getUserBidItemsByProductId = async (
  userDetails: userViewBidsRequest
) => {
  console.log("userdetailshook", userDetails);
  const { email, password, productId } = userDetails;
  const url = `${API_URL}/api/bids/${email}/${productId}`;
  const { data } = await axios.get(url, {
    data: {
      email,
      productId,
    },
    headers: {
      Authorization: `Bearer ${password}`,
    },
  });
  console.log("data", data);

  return data;
};
// const useViewUserBids = (request: userViewBidsRequest) => {
//   console.log("request", request);

//   async function getBidItemsByUser(userDetails: userViewBidsRequest) {
//     console.log("userdetailshook", userDetails);
//     const { email, password, productId } = userDetails;
//     console.log("request", request);
//     const url = `${process.env.REACT_APP_API_URL}/api/${email}/${productId}`;
//     const { data } = await axios.get(url, {
//       data: {
//         email,
//         productId,
//       },
//       headers: {
//         Authorization: `Bearer ${password}`,
//       },
//     });
//     console.log("data", data);

//     return data;
//   }
//   return useQuery(["viewbids"], {
//     queryFn: () => getBidItemsByUser(request),
//     refetchOnWindowFocus: false,
//     onSuccess: (data) => {
//       toast({
//         type: "success",
//         title: "Request to view bids successful",
//         message: "View your bids for this item",
//       });
//     },
//     onError: (error: any) => {
//       toast({
//         type: "error",
//         title: "Something went wrong",
//         message:
//           error?.message ||
//           "An error has occured. We were unable to view your bids for this item. Please try again later.",
//       });
//     },
//   });
// };
