import * as React from "react";
import hotToast, { Toaster as HotToaster } from "react-hot-toast";

import clsx from "clsx";
import { Icons } from "src/components/ui/Icons/Icons";

export const Toaster = HotToaster;

interface ToastProps extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  onClose?: () => void;
}

export function Toast({ visible, className, ...props }: ToastProps) {
  return (
    <div
      className={clsx(
        "min-h-16 mb-2 flex w-[350px] flex-col gap-1 rounded-md bg-white px-6 py-4 shadow-lg",
        visible && "animate-in slide-in-from-bottom-5",
        className
      )}
      {...props}
    />
  );
}

interface ToastIconProps extends Partial<React.SVGProps<SVGSVGElement>> {
  name: keyof typeof Icons;
}

Toast.Icon = function ToastIcon({ name, className, ...props }: ToastIconProps) {
  const Icon = Icons[name];

  if (!Icon) {
    return null;
  }

  return (
    <div className="flex h-20 w-20 items-center justify-center rounded-full bg-slate-100">
      <Icon className={clsx("h-10 w-10", className)} {...props} />
    </div>
  );
};

interface ToastTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {}

Toast.Title = function ToastTitle({ className, ...props }: ToastTitleProps) {
  return <p className={clsx("text-sm font-medium", className)} {...props} />;
};

interface ToastDescriptionProps
  extends React.HTMLAttributes<HTMLParagraphElement> {}

Toast.Description = function ToastDescription({
  className,
  ...props
}: ToastDescriptionProps) {
  return <p className={clsx("text-sm opacity-80", className)} {...props} />;
};

interface ToastOpts {
  title?: string;
  message: string;
  type?: "success" | "error" | "default" | "loading";
  duration?: number;
}

export function toast(opts: ToastOpts) {
  const { title, message, type = "default", duration = 3000 } = opts;

  return hotToast.custom(
    (t) => (
      <Toast
        visible={t.visible}
        className={clsx({
          "bg-red-600 text-white": type === "error",
          "bg-gray-900 text-white": type === "success",
        })}
      >
        <div className="flex-col max-w-full">
          <div className="flex justify-between">
            <div>
              {type === "loading" ? (
                <Icons.loading />
              ) : type === "error" ? (
                <Icons.error className="h-6 w-6 text-white" />
              ) : type === "success" ? (
                <Icons.success className="h-6 w-6 text-white" />
              ) : null}
            </div>
            <div className="ml-10">
              {
                <button onClick={() => hotToast.dismiss(t.id)}>
                  <Icons.close className="h-6 w-6 text-white" />
                </button>
              }
            </div>
          </div>
          <Toast.Title>{title}</Toast.Title>
          {message && <Toast.Description>{message}</Toast.Description>}
        </div>
      </Toast>
    ),
    { duration }
  );
}

toast.promise = (...args: Parameters<typeof hotToast.promise>) =>
  hotToast.promise(...args);
