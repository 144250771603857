import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { IconLoader } from "./components/Loading";
import * as OutlineIcons from "@heroicons/react/24/outline";
import * as SolidIcons from "@heroicons/react/24/solid";

export type IconName = keyof typeof SolidIcons | keyof typeof OutlineIcons;
export type IconVariant = "solid" | "outline";

export const Icons = {
  close: XCircleIcon,
  success: CheckCircleIcon,
  error: ExclamationCircleIcon,
  loading: IconLoader,
};
