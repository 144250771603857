import React from "react";
import { Product } from "src/types/product";
import { BidForm } from "src/components/BidForm/BidForm";
import { ViewBidsForm } from "src/components/ViewBidsForm";
import cn from "clsx";
import {
  capitalizeFirstLetter,
  formatDate,
  convertObjectToArray,
} from "src/lib/helpers";
import RegisterProfile from "src/pages/RegisterProfile";

interface BidFormContainerProps {
  product: Product;
}

const tabs = [
  { name: "Submit Bid", href: "#bidform" },
  // { name: "View Bids", href: "#viewbids" },
];

const BidFormContainer = ({ product }: BidFormContainerProps) => {
  const [activeTab, setActiveTab] = React.useState("Submit Bid");

  console.log("activeTab", activeTab);

  const handleTabChange = (tab: string) => {
    console.log("tab", tab);
    setActiveTab(tab);
  };

  //exclude images from the list
  const { images, id, ...productWithoutImages } = product;
  const productId = product.id;

  const productArray = convertObjectToArray(productWithoutImages);
  console.log("productArray", productArray);

  const excludedKeys = ["id", "title"];

  return (
    <div className="sm:max-w-lg md:max-w-md gap-6 grid grid-cols-1">
      <div>
        <h2 className="text-xl mb-3 tracking-tight font-medium">
          {product.title}
        </h2>
        {product &&
          Object.entries(productWithoutImages).map(([key, value]) => {
            console.log("key", key, "value", value);
            if (!excludedKeys.includes(key) && value) {
              const capitalizedKey = capitalizeFirstLetter(key);
              return (
                <li key={key} className="list-none">
                  <>
                    <span className="font-bold mr-2">{capitalizedKey}:</span>
                    {key === "close_Date" ? formatDate(value) : value}
                  </>
                </li>
              );
            }
            return null;
          })}
      </div>
      <hr></hr>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={activeTab}
            onChange={(event) => {
              handleTabChange(event.target.value);
            }}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={cn(
                    tab.name === activeTab
                      ? "border-[#991b1b] text-[#991b1b]"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.name === activeTab ? "page" : undefined}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(e.currentTarget.innerText);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        <div>
          {activeTab === "Submit Bid" ? (
            <BidForm productId={productId} />
          ) : (
            <ViewBidsForm productId={productId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BidFormContainer;
