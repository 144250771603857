import Logo from "../../assets/images/custodian-logo-white.png";
import { NavLink } from "react-router-dom";

export interface Props {
  children?: React.ReactNode; //optional children
  className?: string;
}

//add custom word-break: break words for footer overflow email address

export const Footer: React.FC<Props> = ({ className, children }) => {
  return (
    <>
      <footer className="bg-gray-900 w-full">
        <div className="max-w-[1240px] mx-auto py-16 px-2 grid md:grid-cols-2 lg:grid-cols-4 text-[#f5f5f4]">
          <div className="px-4 py-2">
            <div className="text-2xl font-bold">
              <NavLink to="/">
                <img src={Logo} alt="Custodian Logo" />
              </NavLink>
            </div>
          </div>
          <div className="px-4 py-2">
            <h2 className="font-bold border-b-2 mb-2">OUR DRIVE</h2>
            <p className="text-sm">
              Central to Custodian's operation is our vision to be Africa's
              Insurer of choice, fuelled daily by a relentless resolve to
              provide innovative insurance products that meet our customer
              needs, whilst ensuring we remain efficient and productive.
            </p>
          </div>
          <div className="px-4 py-2">
            <h2 className="font-bold border-b-2 mb-2">OUR SUBSIDIARIES</h2>
            <p className="py-1 text-sm hover:underline">
              <a href="https://custodianplc.com.ng/custodian-and-allied-insurance-limited">
                Custodian and Allied Insurance Ltd
              </a>
            </p>
            <p className="py-1 text-sm hover:underline">
              <a href="https://custodianplc.com.ng/custodian-life-assurance-limited">
                Custodian Life Assurance Ltd{" "}
              </a>
            </p>
            <p className="py-1 text-sm hover:underline">
              <a href="http://www.crusaderpensions.com/">
                CrusaderSterling Pensions Ltd{" "}
              </a>
            </p>
            <p className="py-1 text-sm hover:underline">
              <a href="https://custodianplc.com.ng/trustees-limited">
                Custodian Trustees Ltd
              </a>
            </p>
            <p className="py-1 text-sm hover:underline">
              <a href="https://updcplc.com/">
                UACN Property Development Company Plc.
              </a>
            </p>
          </div>
          <div className="px-4 py-2">
            <h2 className="font-bold border-b-2 mb-2">CONTACT US</h2>
            <address className="py-1 text-sm">
              CUSTODIAN HOUSE 16A, Commercial Avenue, Sabo, Yaba, Lagos.
            </address>
            <p className="py-1 text-sm">
              Tel: (+234) 1 2707206-7, 2793740, 27937401 0700-CUSTODIAN, (+234)
              1 2774000-9, Fax: (+234) 1 2707203
            </p>
            <p className="py-1 text-sm">P. O. Box 2101, Lagos.</p>
            <p className="py-1 text-sm">
              Email: enquiries@custodianinsurance.com
            </p>
          </div>
        </div>

        <div className=" max-w-[1240px] mx-auto text-center py-2 border-t-2">
          <p className="text-sm text-gray-500 space-x-2">
            <span className="block sm:inline">All rights reserved</span>

            <span className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
              &copy; 2022 Custodian and Allied Plc.
            </span>

            <span className="space-x-1">
              <a
                className="inline-block text-[#991b1b] underline transition hover:text-red-500"
                href="/"
              >
                Terms & Conditions
              </a>

              <span>&middot;</span>

              <a
                className="inline-block text-[#991b1b] underline transition hover:text-red-500"
                href="/"
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
