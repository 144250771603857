import React, { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
  children?: React.ReactNode;
  user: any;
  isAllowed: boolean;
  redirectPath?: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  user,
  isAllowed,
  redirectPath = "/login",
  children,
}) => {
  console.log("protected user", user);
  console.log("protected isAllowed", isAllowed);

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet /> || <>{children}</>;
};

export default ProtectedRoute;
