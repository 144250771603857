import React, { FC } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "../ui";
import { Button } from "src/components/ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as z from "zod";
import { encodeBase64 } from "src/lib/helpers";
import cn from "clsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { userAuthSchema } from "src/lib/validations/auth";
import { TextField } from "../ui/TextFields/TextFields";
import registerBidder from "src/lib/register-bidder";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../ui/LoadingSpinner/LoadingSpinner";

interface RegisterProfileFormProps {
  className?: React.HTMLAttributes<HTMLDivElement>;
  // props: React.HTMLAttributes<HTMLDivElement>;
}
// extends React.HTMLAttributes<HTMLDivElement> {}

type FormInput = z.infer<typeof userAuthSchema>;

const RegisterProfileForm = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(userAuthSchema),
  });

  const { mutate, isLoading, isError, isSuccess, error, data } =
    useMutation(registerBidder);

  const onSubmit: SubmitHandler<FormInput> = async (submittedData) => {
    const { confirmPassword, ...data } = submittedData;
    data.password = encodeBase64(data.password);
    console.log("submitteddata", data);
    mutate(data, {
      onSuccess: (data) => {
        console.log("data", data);
        toast({
          type: "success",
          title: "Profile Created",
          message:
            "Your profile has been successfully created. You can now make a bid.",
        });
        // setTimeout(() => {
        //   //navigate to home page
        //   navigate(-1);
        // }, 5000);
      },
      onError: (error: any) => {
        console.log("error", error);
        if (error.response.data.errorCode === "SVP4001-006") {
          toast({
            type: "error",
            title: error.response.data.errorCode,
            message: error.response.data.message,
          });
        } else {
          // toast({
          //   type: "error",
          //   title: error.response.data || "Something went wrong",
          //   message: error?.message || "An error has occured",
          // });
        }
      },
    });

    //for testing purposes only
    // if (isSuccess) {
    //   alert(data);
    // }
  };

  // React.useEffect(() => {
  //   if (formState.isSubmitSuccessful) {
  //     reset();
  //   }
  // }, [formState, reset]);

  /**uncomment below to view input values 'onchange' in console */
  // console.log(watch("firstName"));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-full">
            <label
              htmlFor="email"
              className="mb-2 block text-sm font-semibold text-gray-900"
            >
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className={cn(
                "col-span-full my-1 block w-full appearance-none rounded-lg border border-gray-200 bg-white text-gray-900 placeholder:text-gray-400 focus:border-[#991b1b] focus:outline-none  sm:text-sm",
                {
                  "focus:ring-red-500 border-red-500 focus:border-red-500":
                    errors.email,
                }
              )}
              {...register("email")}
            />
            {errors?.email && (
              <p className="px-1 text-xs text-red-600">
                {errors.email.message}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="password"
              className="mb-2 block text-sm font-semibold text-gray-900"
            >
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              className={cn(
                "my-1 block w-full appearance-none rounded-lg border border-gray-200 bg-white text-gray-900 placeholder:text-gray-400 focus:border-[#991b1b] focus:outline-none  sm:text-sm",
                {
                  "focus:ring-red-500 border-red-500 focus:border-red-500":
                    errors.password,
                }
              )}
              {...register("password")}
            />
            {errors?.password && (
              <p className="px-1 text-xs text-red-600">
                {errors.password.message}
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="confirm_password"
              className="mb-2 block text-sm font-semibold text-gray-900"
            >
              Confirm Password
            </label>
            <input
              id="confirm_password"
              name="confirmPassword"
              type="password"
              autoComplete="confirm new-password"
              className={cn(
                "my-1 block w-full appearance-none rounded-lg border border-gray-200 bg-white text-gray-900 placeholder:text-gray-400 focus:border-[#991b1b] focus:outline-none  sm:text-sm",
                {
                  "focus:ring-red-500 border-red-500 focus:border-red-500":
                    errors.confirmPassword,
                }
              )}
              {...register("confirmPassword")}
            />
            {errors?.confirmPassword && (
              <p className="px-1 text-xs text-red-600">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
        </div>
        <button
          disabled={isLoading}
          type="submit"
          className="bg-[#991b1b] text-white mt-8 font-semibold px-4 py-2 w-full rounded-md flex justify-center items-center hover:opacity-75"
        >
          {isLoading ? (
            <div className="text-white">
              <LoadingSpinner />
            </div>
          ) : (
            "Create Your Profile"
          )}
        </button>
      </form>
    </>
  );
};

export default RegisterProfileForm;
