import {
  MouseEventHandler,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { biditems } from "src/lib/dummyadmindata";

type SortOrder = "asc" | "desc";
type Data = typeof biditems;
type SortKeys = keyof Data[0];

const ViewBidItems = ({ user }) => {
  const checkbox = useRef(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedBidItem, setSelectedBidItem] = useState([]);
  const [sortKey, setSortKey] = useState<SortKeys>("uploadedAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  const navigate = useNavigate();

  console.log("viewbid items user", user);

  const statusStyles = {
    available: "bg-green-100 text-green-800",
    closed: "bg-yellow-100 text-yellow-800",
    sold: "bg-gray-100 text-gray-800",
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
  }: {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }) {
    return (
      <span
        onClick={onClick}
        className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
      >
        <ChevronDownIcon
          className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
          aria-hidden="true"
        />
      </span>
    );
  }

  function sortData({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Data;
    sortKey: SortKeys;
    reverse: boolean;
  }) {
    if (!sortKey) return tableData;

    const sortedData = biditems.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  }

  const sortedData = useCallback(
    () =>
      sortData({ tableData: biditems, sortKey, reverse: sortOrder === "desc" }),
    [biditems, sortKey, sortOrder]
  );

  console.log("sortedData", sortedData());

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedBidItem.length > 0 && selectedBidItem.length < biditems.length;
    setChecked(selectedBidItem.length === biditems.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedBidItem]);

  function toggleAll() {
    setSelectedBidItem(checked || indeterminate ? [] : biditems);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function changeSort(key: SortKeys) {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");

    setSortKey(key);
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900 mt-5">
            Bid Items
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all bid items to view, edit, or delete.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => navigate("/createbiditem")}
          >
            Add Bid Item
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {selectedBidItem.length > 0 && (
                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                  <button
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                  >
                    Bulk edit
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                  >
                    Delete all
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    >
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Name
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Status
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Closing Date
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Reg.No
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Location
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Reserve Price
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Disposal Price
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Created By
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Approved By
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Updated At
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Claim No
                        <SortButton
                          columnKey={"name"}
                          sortKey={sortKey}
                          sortOrder={sortOrder}
                          onClick={() => changeSort("name")}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {biditems.map((biditem) => (
                    <tr
                      key={biditem.id}
                      className={
                        selectedBidItem.includes(biditem)
                          ? "bg-gray-50"
                          : undefined
                      }
                    >
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                        {selectedBidItem.includes(biditem) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                          value={biditem.id}
                          checked={selectedBidItem.includes(biditem)}
                          onChange={(e) =>
                            setSelectedBidItem(
                              e.target.checked
                                ? [...selectedBidItem, biditem]
                                : selectedBidItem.filter((p) => p !== biditem)
                            )
                          }
                        />
                      </td>
                      <td
                        className={classNames(
                          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                          selectedBidItem.includes(biditem)
                            ? "text-indigo-600"
                            : "text-gray-900"
                        )}
                      >
                        {biditem.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {biditem.status}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {biditem.closingDate}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {biditem.regNo}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {biditem.location}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {biditem.reservePrice}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {biditem.disposalPrice}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {biditem.createdBy}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {biditem.approvedBy}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {biditem.uploadedAt}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {biditem.claimNo}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a
                          href="#"
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit<span className="sr-only">, {biditem.name}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBidItems;
