import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Product } from "src/types/product";
import { API_URL } from "src/config/environmentvariables";

/**Dummy url for api demo purposes */
const useProduct = (productId: string | number) => {
  const getProductById = async (
    id: string | number
  ): Promise<Product | any> => {
    const url = `${API_URL}/api/biditems/${id}`;
    // const url = `${dummyapi}/${id}`;
    const { data } = await axios.get(url);
    return data;
  };
  return useQuery(["product", productId], () => getProductById(productId));
};

export default useProduct;
