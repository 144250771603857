import { AuthLayout } from "src/components/Auth/AuthLayout";
import { RegisterProfileForm } from "src/components/RegistrationForms";

function Register() {
  return (
    <>
      <AuthLayout
        title="Create Your Profile Account"
        subtitle="Before you can submit a bid, you need to create a profile account."
      >
        <RegisterProfileForm />
      </AuthLayout>
    </>
  );
}

export default Register;
