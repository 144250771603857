import type { FC } from "react";
import { ProductCard } from "src/components/product";
import { LoadingSpinner } from "src/components/ui";
import useAllProducts from "src/lib/hooks/use-all-products";

interface HomeProps {}

const Home: FC<HomeProps> = ({}) => {
  const { isError, data: products, isLoading, error } = useAllProducts();

  return (
    <>
      <div className="w-full py-[3rem] px-[1rem]">
        {isLoading && (
          <div className="min-h-screen flex items-center justify-center p-3">
            <LoadingSpinner />
          </div>
        )}
        <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pb-4">
          {products &&
            products.map((product: any) => {
              return <ProductCard key={product.id} product={product} />;
            })}
        </div>
      </div>
    </>
  );
};
export default Home;
