import styles from "./Navbar.module.css";
import Logo from "../../assets/images/custodian-logo-white.png";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar: React.FC = () => {
  const [sidebarView, setSidebarView] = useState(false);

  const openSidebar = () => {
    setSidebarView(!sidebarView);
  };

  return (
    <>
      <div className="drop-shadow-md bg-[#991b1b] px-6 w-full sm:px-6 lg:px-8">
        <div className="max-w-[1240px] flex mx-auto  text-[#f5f5f4] flex-row justify-between py-4 md:py-4">
          <NavLink to="/">
            <a className="block" href="/">
              <span className="sr-only">Home</span>
              <img src={Logo} width={100} alt="Custodian Logo" />
            </a>
          </NavLink>
          <nav className="sm:flex justify-center items-center md:mr-3 lg:mr-1">
            <NavLink className="p-4 hover:underline mr-2" to="/">
              Home
            </NavLink>
            {/* <NavLink
              className="p-3 hover:underline bg-[#ffffff] font-medium border text-[#991b1b] rounded-full"
              to="/account-creation-request"
            >
              Create Account
            </NavLink> */}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navbar;
