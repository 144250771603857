import axios from "axios";
import { API_URL } from "src/config/environmentvariables";

import { registerBidderProps } from "src/types/registerbidder";

const url = `${API_URL}/api/bidders/profile`;

const registerBidder = async (bid: registerBidderProps) => {
  console.log("bid", bid);
  const { password, ...userDetails } = bid;
  const { data } = await axios.post(url, userDetails, {
    headers: {
      Authorization: `Bearer ${password}`,
    },
  });

  console.log(data);
  return data;
};

export default registerBidder;
