import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Product } from "src/types/product";
import { API_URL } from "src/config/environmentvariables";

/**fake api used for demo purpose */
const useAllProducts = () => {
  const fetchProducts = async (): Promise<Product[] | any[]> => {
    const url = `${API_URL}/api/biditems`;

    const { data: products } = await axios.get(url);

    console.log("products", products);

    return products;
  };
  return useQuery(["products"], fetchProducts);
};

export default useAllProducts;
