import { Base64 } from "js-base64";

export const capitalizeFirstLetter = (string) => {
  return string.slice(0, 1).toUpperCase().concat(string.slice(1));
};

//create a function to format the date that includes milliseconds to MM/DD/YYYY

export function formatDate(dateString) {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

// export async function hashPassword(plainTextPassword: string) {
//   try {
//     const saltRounds = 10;
//     const passwordHash = await bcrypt.hash(plainTextPassword, saltRounds);
//     return passwordHash;
//   } catch (error) {
//     console.log(error);
//     throw new Error("Error hashing password");
//   }
// }

// export async function comparePassword(
//   plainTextPassword: string,
//   passwordHash: string
// ) {
//   try {
//     const passwordMatch = await bcrypt.compare(plainTextPassword, passwordHash);
//     return passwordMatch;
//   } catch (error) {
//     console.log(error);
//     throw new Error("Error comparing password");
//   }
// }

export function convertObjectToArray(object) {
  return Object.entries(object);
}

export function encodeBase64(string) {
  return Base64.encode(string);
}
