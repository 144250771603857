import Logo from "src/assets/images/custodian-logo-transparent.png";
import { useNavigate } from "react-router-dom";

function BackgroundIllustration(props) {
  return (
    <svg
      viewBox="0 0 1090 1090"
      aria-hidden="true"
      fill="none"
      preserveAspectRatio="none"
      {...props}
    >
      <circle cx={545} cy={545} r="544.5" />
      <circle cx={545} cy={545} r="480.5" />
      <circle cx={545} cy={545} r="416.5" />
      <circle cx={545} cy={545} r="352.5" />
    </svg>
  );
}

export function AuthLayout({ title, subtitle, children }) {
  const navigate = useNavigate();
  return (
    <main className="flex min-h-full overflow-hidden sm:py-15">
      {/* <div className="flex px-4 pt-8 pb-0 lg:px-8 max-w-6xl mx-auto">
        <BackButton />
      </div> */}
      <div className="mx-auto flex w-full max-w-2xl flex-col px-4 sm:px-6">
        {/* <a aria-label="Home" onClick={() => navigate(-1)}>
          <BackButton />
          <img className="mx-auto h-14 w-auto" src={Logo} alt="Your Company" />
        </a> */}
        <div className="relative">
          {/* <BackgroundIllustration
            width="1090"
            height="1090"
            className="absolute -top-7 left-1/2 -z-10 h-[788px] -translate-x-1/2 stroke-gray-300/30 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:-top-9 sm:h-auto"
          /> */}
          <h1 className="text-center text-2xl font-medium tracking-tight text-gray-900">
            {title}
          </h1>
          {subtitle && (
            <p className="mt-3 text-center text-lg text-gray-600">{subtitle}</p>
          )}
        </div>
        <div className="-mx-4 mt-5 bg-white flex-auto py-2 px-2 shadow-2xl shadow-gray-900/10 sm:mx-0 sm:flex-none sm:rounded-5xl sm:p-5">
          {children}
        </div>
      </div>
    </main>
  );
}

const BackButton = () => (
  <>
    <a
      className="group flex font-semibold text-sm leading-6 text-slate-700 hover:text-slate-900 dark:text-slate-200 dark:hover:text-white"
      href="/"
    >
      <svg
        viewBox="0 -9 3 24"
        className="overflow-visible mr-3 text-slate-400 w-auto h-6 group-hover:text-slate-600 dark:group-hover:text-slate-300"
      >
        <path
          d="M3 0L0 3L3 6"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
      Go back
    </a>
  </>
);
