import { FC } from "react";
import { useParams } from "react-router-dom";
import { ProductView } from "src/components/product";
import { LoadingSpinner } from "src/components/ui";
import useProduct from "src/lib/hooks/use-product";

interface SingleProductProps {}

const SingleProduct: FC<SingleProductProps> = ({}) => {
  let params = useParams();
  let id = parseInt(params.id!);

  const { data: product, isLoading, error } = useProduct(id);

  if (error instanceof Error) {
    return <div>An error occurred: {error.message}</div>;
  }

  return (
    <div>
      {isLoading && (
        <div className="min-h-screen flex items-center justify-center p-3">
          <LoadingSpinner />
        </div>
      )}
      {product && <ProductView product={product} />}
    </div>
  );
};
export default SingleProduct;
