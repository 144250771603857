import type { FC } from "react";
import { Product } from "src/types/product";
import { useState } from "react";
import cn from "clsx";
import { BidFormContainer } from "src/components/BidFormContainer";
import placeholderImg from "src/assets/images/product-image-placeholder.svg";

interface ProductViewProps {
  product: Product;
}

const ProductView: FC<ProductViewProps> = ({ product }) => {
  const productImages = product?.images || [];
  const productThumbnail = productImages.find(
    (image) => image.imageTag === "Thumbnail"
  );
  const defaultImg = productThumbnail
    ? productThumbnail.imageName
    : placeholderImg;
  const [selectedImg, setSelectedImg] = useState(defaultImg);
  console.log("selected img", selectedImg);
  console.log("product", product);

  return (
    <>
      <section className="min-h-screen">
        <div className="flex px-4 pt-8 pb-0 lg:px-8 max-w-6xl mx-auto">
          <a
            className="group flex font-semibold text-sm leading-6 text-slate-700 hover:text-slate-900 dark:text-slate-200 dark:hover:text-white"
            href="/"
          >
            <svg
              viewBox="0 -9 3 24"
              className="overflow-visible mr-3 text-slate-400 w-auto h-6 group-hover:text-slate-600 dark:group-hover:text-slate-300"
            >
              <path
                d="M3 0L0 3L3 6"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            Go back
          </a>
        </div>
        <div className="container max-w-6xl grid md:grid-cols-2 gap-8 py-10 px-5 mx-auto">
          <div className="sticky">
            <img
              src={selectedImg}
              alt={product.title || "Product Image"}
              className="object-contain border rounded-xl w-full h-[280px] lg:h-[400px]"
            />
            <div className="grid grid-cols-4 gap-4 mt-4">
              {product.images &&
                product.images.map((image, index) => {
                  return (
                    <img
                      key={index}
                      src={image.imageName}
                      alt={product.title}
                      className={cn(
                        "w-full hover:shadow-xl hover:opacity-70 cursor-pointer border md:shadow-lg object-contain h-20 lg:h-32",
                        {
                          "border-[#991b1b] border-[2px]":
                            selectedImg === image.imageName,
                        }
                      )}
                      onClick={() => setSelectedImg(image.imageName)}
                    />
                  );
                })}
            </div>
          </div>
          <div>{product && <BidFormContainer product={product} />}</div>
        </div>
      </section>
    </>
  );
};
export default ProductView;
