export const biditems = [
  {
    id: 1,
    name: "TOYOTA Camry X LE",
    regNo: "T5495LA",
    location: "GODDEY WORKSHOP LIMITED; 2 AMBA COURT STREET, ASABA, DELTA.",
    href: "#",
    reservePrice: "N150,000",
    status: "available",
    closingDate: "July 11, 2023",
    datetime: "2023-07-11",
    createdBy: "abel@custodian.com",
    claimNo: "CUST/2021/0001",
    approvedBy: "sam@custodian.com",
    uploadedAt: "2021-07-11T19:20:38.611Z",
    disposalPrice: "N350,000",
    createdAt: "2021-07-11T19:20:38.611Z",
  },
  {
    id: 2,
    name: "AUDI Camry X LE",
    regNo: "T5495LA",
    location: "GODDEY WORKSHOP LIMITED; 2 AMBA COURT STREET, ASABA, DELTA.",
    href: "#",
    reservePrice: "N120,000",
    status: "sold",
    closingDate: "July 11, 2024",
    datetime: "2023-07-11",
    createdBy: "abel@custodian.com",
    claimNo: "CUST/2021/0001",
    approvedBy: "sam@custodian.com",
    uploadedAt: "2022-07-11T19:20:38.611Z",
    disposalPrice: "N150,000",
    createdAt: "2021-07-11T19:20:38.611Z",
  },
  {
    id: 3,
    name: "BMW Camry X LE",
    regNo: "T5495LA",
    location: "GODDEY WORKSHOP LIMITED; 2 AMBA COURT STREET, ASABA, DELTA.",
    href: "#",
    reservePrice: "N250,000",
    status: "closed",
    closingDate: "July 11, 2023",
    datetime: "2023-07-11",
    createdBy: "abel@custodian.com",
    claimNo: "CUST/2021/0001",
    approvedBy: "sam@custodian.com",
    uploadedAt: "2023-07-11T19:20:38.611Z",
    disposalPrice: "N350,000",
    createdAt: "2021-07-11T19:20:38.611Z",
  },
  {
    id: 4,
    name: "TOYOTA Camry X LE",
    regNo: "T5495LA",
    location: "GODDEY WORKSHOP LIMITED; 2 AMBA COURT STREET, ASABA, DELTA.",
    href: "#",
    reservePrice: "N380,000",
    status: "available",
    closingDate: "July 11, 2025",
    datetime: "2023-07-11",
    createdBy: "abel@custodian.com",
    claimNo: "CUST/2021/0001",
    approvedBy: "sam@custodian.com",
    uploadedAt: "2020-07-11T19:20:38.611Z",
    disposalPrice: "N350,000",
    createdAt: "2021-07-11T19:20:38.611Z",
  },
  // More biditems...
];

export const logData = [];
