import { AuthLayout } from "src/components/Auth/AuthLayout";
import { BidderAccountRequestForm } from "src/components/RegistrationForms";
function BidderAccountCreationRequest() {
  return (
    <>
      <AuthLayout
        title="Request a bidder account"
        subtitle="Before you can submit a bid, you need an approved bidder account. Please complete the form below and we will review your request and get back to you as soon as possible."
      >
        <BidderAccountRequestForm />
      </AuthLayout>
    </>
  );
}

export default BidderAccountCreationRequest;
