import * as z from "zod";

export const userAuthSchema = z
  .object({
    // firstName: z.string().min(3, { message: "First name is too short" }),
    // lastName: z.string().min(3, { message: "Last name is too short" }),
    email: z.string().email({ message: "Invalid email address" }),
    password: z
      .string()
      .min(6, { message: "Password must be at least 6 characters long." }),
    confirmPassword: z
      .string()
      .min(6, { message: "Password must be at least 6 characters long." }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export const accountRequestSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  phoneNumber: z.string().min(11, { message: "Phone number is too short" }),
});
