import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout, AdminLayout } from "./components";
import {
  Home,
  NoMatch,
  Products,
  SingleProduct,
  Login,
  Dashboard,
  Reports,
  CreateBidItem,
  ProtectedRoute,
  ViewBidItems,
  Register,
  BidderAccountRequest,
} from "./pages";
import { useLocalStorage } from "./lib/hooks/use-local-storage";
import { Toaster } from "./components/ui";

const queryClient = new QueryClient();

function App() {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();
    setUser({
      id: "1",
      email: event.target.email.value,
      password: event.target.password.value,
      roles: ["editor"],
    });
    navigate("/dashboard");
  };
  const handleLogout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="top-right" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="products">
            <Route index element={<Products />} />
            <Route path=":id" element={<SingleProduct />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="register" element={<Register />} />
        {/* <Route
          path="account-creation-request"
          element={<BidderAccountRequest />}
        /> */}
        <Route path="login" element={<Login handleLogin={handleLogin} />} />
        <Route path="/" element={<AdminLayout handleLogout={handleLogout} />}>
          <Route element={<ProtectedRoute user={user} isAllowed={!!user} />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route
              path="reports"
              element={
                <ProtectedRoute
                  redirectPath="/dashboard"
                  user={user}
                  isAllowed={!!user && user.roles.includes("admin")}
                >
                  <Reports />
                </ProtectedRoute>
              }
            />
            <Route
              path="createbidItem"
              element={<CreateBidItem user={user} />}
            />
            <Route path="viewbiditems" element={<ViewBidItems user={user} />} />
          </Route>
        </Route>
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
