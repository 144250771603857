import React, { FC } from "react";
import { AdminNavbar } from "../Navbar";
import { AdminFooter } from "../Footer";
import { Outlet } from "react-router-dom";

interface LayoutProps {
  children?: React.ReactNode;
  handleLogout: () => void;
}

const AdminLayout: FC<LayoutProps> = ({ handleLogout }) => {
  return (
    <>
      <div className="min-h-full">
        <AdminNavbar handleLogout={handleLogout} />
        <div className="flex flex-1 flex-col lg:pl-64">
          <main className="flex-1 pb-8 bg-gray-100">
            {/* Page header */}
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
