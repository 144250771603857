import React, { FC } from "react";
import { Product } from "src/types/product";
import * as z from "zod";
import { useQuery } from "@tanstack/react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoadingSpinner, toast } from "../ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import cn from "clsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { userViewBidsSchema } from "src/lib/validations/bidformsvalidation";
import { encodeBase64, formatDate } from "src/lib/helpers";
import { getUserBidItemsByProductId } from "src/lib/userBids";
import { useNavigate } from "react-router-dom";

const dummyResponseData = [
  {
    bidItemId: 1,
    Id: 20,
    email: "mike@gmail.com",
    bidValue: 1000,
    narration: "hello this is a test bid",
    created_At: "2021-09-10T12:00:00.000Z",
    status: "pending",
  },
  {
    bidItemId: 1,
    Id: 21,
    email: "mike@gmail.com",
    bidValue: 1000,
    narration: "hello this is a test bid",
    created_At: "2021-09-10T12:00:00.000Z",
    status: "pending",
  },
];

interface ViewBidsFormProps {
  productId: number;
}

type ViewBidsFormInput = z.infer<typeof userViewBidsSchema>;

type ExtendedViewBidsFormInput = ViewBidsFormInput & {
  productId?: number;
};

const ViewBidsForm = ({ productId }: ViewBidsFormProps) => {
  let navigate = useNavigate();

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState,
    formState: { errors },
  } = useForm<ViewBidsFormInput>({
    resolver: zodResolver(userViewBidsSchema),
  });

  const onSubmit: SubmitHandler<ExtendedViewBidsFormInput> = async (
    userdetails
  ) => {
    setLoading(true);
    userdetails.password = encodeBase64(userdetails.password);
    const userDetailsWithProductId = { ...userdetails, productId };
    console.log("userdetails", userDetailsWithProductId);
    const response = getUserBidItemsByProductId(userDetailsWithProductId);
    console.log("userBids", response);
    toast.promise(response, {
      loading: "Loading...",
      success: (data: any) => {
        console.log(data);
        setData(data);
        return (
          <div>
            <p>Success</p>
            <p>{`Your bids have been retrieved.`}</p>
          </div>
        );
      },
      error: (error) => {
        console.log(error);
        if (
          error.response.data.errorCode === "SVP4001-003" ||
          "SVP4001-005" ||
          "SVP4001-001"
        ) {
          return (
            <div>
              <p>{error.response.data && error.response.data.errorCode}</p>
              <p>{error.response.data && error.response.data.message}</p>
              {/* <p>{error.message}</p> */}
            </div>
          );
        }

        if (error.response.data.errorCode === "SVP4001-002") {
          // Wait for 3 seconds before redirecting to the home page
          setTimeout(() => {
            //navigate to register page
            navigate("/register");
          }, 4000);
          return (
            <div>
              <p>{error.response.data && error.response.data.errorCode}</p>
              <p>{error.response.data && error.response.data.message}</p>
              {/* <p>{error.message}</p> */}
            </div>
          );
        }

        if (error.response.data.errorCode === "SVP4001-004") {
          // Wait for 3 seconds before redirecting to the home page
          setTimeout(() => {
            //navigate to account creation page
            navigate("/account-creation-request");
          }, 4000);
          return (
            <div>
              <p>{error.response.data && error.response.data.errorCode}</p>
              <p>{error.response.data && error.response.data.message}</p>
              {/* <p>{error.message}</p> */}
            </div>
          );
        } else {
          return (
            <div>
              <p>{error.response.data && error.response.data.errorCode}</p>
              <p>{error.response.data && error.response.data.message}</p>
              {/* <p>{error.message}</p> */}
            </div>
          );
        }
      },
    });
    setLoading(false);
  };

  //   if (isError) return <div>Error: {error.message}</div>;

  // const userBids = getBidItemByUser(data?.email);
  // console.log("userBids", userBids)

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  /**uncomment below to view input values 'onchange' in console */
  // console.log(watch("firstName"));

  return (
    <>
      <h2 className="text-xl font-semibold mt-3">View Your Bids</h2>
      {!data && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-3">
            <label htmlFor="email" className="block">
              <input
                id="email"
                name="email"
                autoComplete="email"
                {...register("email")}
                type="email"
                placeholder="Email Address"
                className={cn(
                  "mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:ring-0",
                  {
                    "focus:ring-red-500 border-red-500 focus:border-red-500":
                      errors.email,
                  }
                )}
              />
              {errors?.email && (
                <p className="px-1 text-xs text-red-600">
                  {errors.email.message}
                </p>
              )}
            </label>
            <label htmlFor="password" className="block">
              <input
                id="password"
                name="password"
                {...register("password")}
                placeholder="Password "
                type="password"
                className={cn(
                  "mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:ring-0",
                  {
                    "focus:ring-red-500 border-red-500 focus:border-red-500":
                      errors.password,
                  }
                )}
              />
              {errors?.password && (
                <p className="px-1 text-xs text-red-600">
                  {errors.password.message}
                </p>
              )}
            </label>
            <button
              disabled={loading}
              type="submit"
              className="bg-[#991b1b] text-white font-bold px-4 py-2 w-full uppercase rounded-md flex justify-center items-center hover:opacity-75"
            >
              {loading ? (
                <div className=" text-white">
                  <LoadingSpinner />
                </div>
              ) : (
                "View Bids"
              )}
            </button>
          </div>
        </form>
      )}
      {/* {dummyResponseData &&
        dummyResponseData.map((item, index) => {
          return (
            <div key={index} className="flex flex-row">
              <div className="basis-1/3">
                <p>{index}</p>
              </div>
              <div className="basis-1/3">
                <p>{item.bidValue}</p>
              </div>
              <div className="basis-1/3">
                <p>{item.created_At}</p>
              </div>
            </div>
          );
        })} */}
      {data && (
        <div className="flex flex-row">
          <div className="basis-1/3">
            <p>No.</p>
          </div>
          <div className="basis-1/3">
            <p>Bid Value</p>
          </div>
          <div className="basis-1/3">
            <p>Bid Status</p>
          </div>
          <div className="basis-1/3">
            <p>Date</p>
          </div>
        </div>
      )}
      {data &&
        data.map((item, index) => {
          return (
            <div className="flex flex-row">
              <div className="basis-1/3">
                <p>{index + 1}</p>
              </div>
              <div className="basis-1/3">
                <p>{item.bidValue}</p>
              </div>
              <div className="basis-1/3">
                <p>{item.status}</p>
              </div>
              <div className="basis-1/3">
                <p>
                  {index === 0
                    ? formatDate(item.created_At)
                    : formatDate(item.updated_At)}
                </p>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default ViewBidsForm;
