import styles from "./ProductCard.module.css";
import { Product } from "src/types/product";
import { FC } from "react";
import placeholderImg from "../../../assets/images/product-image-placeholder.svg";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  product: Product;
  className?: string;
}

const ProductCard: FC<Props> = ({ product, className }) => {
  let navigate = useNavigate();

  const productImages = product.images || [];
  const productThumbnail = productImages.find(
    (image) => image.imageTag === "Thumbnail"
  );
  console.log("product thumbnail", productThumbnail);

  return (
    <>
      <div className="w-full shadow-xl flex flex-col px-2 my-8 pb-2 rounded-lg justify-between">
        <Link to={`/products/${product.id}`}>
          {productThumbnail && (
            <img
              src={productThumbnail.imageName || placeholderImg}
              alt={productThumbnail.imageTag || "Product Image"}
              className="w-50 mx-auto mt-[-1rem] bg-white rounded-lg h-56"
            />
          )}
          {!productThumbnail && (
            <img
              src={placeholderImg}
              alt="Product Image"
              className="w-50 mx-auto mt-[-1rem] bg-white rounded-lg h-56"
            />
          )}
        </Link>

        <h2 className="text-1xl font-bold text-center py-2 border-b-2">
          {product.title}
        </h2>
        <button
          onClick={() => navigate(`products/${product.id}`)}
          className="border-2 border-[#991b1b] 
            text-[#991b1b] hover:bg-[#991b1b] hover:text-[#f5f5f4] 
            duration-700 w-[150px] mx-auto font-medium rounded-md mt-2 py-1"
        >
          <span className="text-sm font-medium uppercase">
            Get More Details
          </span>
        </button>
      </div>
    </>
  );
};

export default ProductCard;
