import axios from "axios";
import { FormInput } from "src/types/bid";
import { API_URL } from "src/config/environmentvariables";

const url = `${API_URL}/api/bids/bidrequest`;

const postBid = async (bid: FormInput) => {
  console.log("bid", bid);
  // const { password, ...userDetails } = bid;
  // const { data, status } = await axios.post(url, userDetails, {
  //   headers: {
  //     Authorization: `Bearer ${password}`,
  //   },
  // });

  const { data, status } = await axios.post(url, bid);

  console.log(data, status);
  return data;
};

export default postBid;
