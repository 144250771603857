import React, { FC, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoadingSpinner, toast } from "../ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormInput } from "src/types/bid";
import postBid from "src/lib/post-bid";
import cn from "clsx";
import { Link } from "react-router-dom";
import { encodeBase64 } from "src/lib/helpers";
import { useNavigate } from "react-router-dom";
import ProfileModalForm from "../ProfileModalForm";

interface BidFormProps {
  productId: number;
}

export const BidForm: FC<BidFormProps> = ({ productId }) => {
  let navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  console.log("modalOpen", modalOpen);

  // let oldBids = {
  //   email: "",
  //   bidValue: 0,
  //   narration: "",
  // };
  // const [email, setEmail] = React.useState(oldBids && oldBids?.email);
  // const [bidValue, setBidValue] = React.useState(oldBids && oldBids?.bidValue);
  // const [narration, setNarration] = React.useState(
  //   oldBids && oldBids?.narration
  // );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: {
      email: "",
      bidValue: null,
      narration: "",
      bidItemId: productId,
      // password: "",
    },
  });

  const { mutate, isLoading, isError, isSuccess, error, data } =
    useMutation(postBid);

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    // data.password = encodeBase64(data.password);
    console.log(data);
    mutate(data, {
      onSuccess: (data) => {
        console.log("data", data);
        toast({
          type: "success",
          title: "Bid Submitted",
          message: "Your bid has been successfully submitted",
        });
      },
      onError: (error: any) => {
        console.log("error", error);
        switch (error.response.data.errorCode) {
          case "SVP4001-004":
            toast({
              type: "error",
              title: error.response.data.errorCode,
              message:
                "You are not a registered bidder. Please contact support: salvage@custodianinsurance.com.",
              duration: 10000,
            });
            break;
          case "SVP4001-002":
            toast({
              type: "error",
              title: error.response.data.errorCode,
              message: "Please change your password to make a bid.",
              duration: 5000,
            });
            setModalOpen(true);
            break;
          case "SVP4001-003":
            toast({
              type: "error",
              title: error.response.data.errorCode,
              message: "Access denied, invalid credentials.",
            });
            break;
          case "SVP4001-005":
            toast({
              type: "error",
              title: error.response.data.errorCode,
              message: "You have exceeded your maximum bids (3/3).",
            });
            break;
          default:
            toast({
              type: "error",
              title: error.response.data.errorCode,
              message: "Something went wrong. Please try again.",
            });
            break;
        }
        reset();
      },
    });
  };

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  /**uncomment below to view input values 'onchange' in console */
  console.log(watch("email"));

  return (
    <>
      <h2 className="text-xl font-semibold mt-3">Submit Your Bid</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-3">
          <label className="block">
            <input
              {...register("email", {
                required: "Your email address is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              type="email"
              placeholder="Email Address"
              className={cn(
                "mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:ring-0",
                {
                  "focus:ring-red-500 border-red-500 focus:border-red-500":
                    errors.email,
                }
              )}
              onChange={(e) => {
                // setEmail(e.target.value);
                setValue("email", e.target.value);
              }}
            />
            <p className="text-sm text-red-500 ">{errors.email?.message}</p>
          </label>
          <label className="block">
            <input
              {...register("bidValue", {
                required: "A bid value is required",
                min: {
                  value: 1000,
                  message: "Please insert a bid value greater than 1000",
                },
                pattern: {
                  value: /^[1-9]+[0-9]*$/,
                  message: "invalid bid value ",
                },
              })}
              placeholder="Bid Value"
              type="number"
              onChange={
                (e) => {
                  setValue("bidValue", parseInt(e.target.value));
                }
                // setBidValue(e.target.value)
              }
              className={cn(
                "mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:ring-0",
                {
                  "focus:ring-red-500 border-red-500 focus:border-red-500":
                    errors.bidValue,
                }
              )}
            />
            <p className="text-sm text-red-500 ">{errors.bidValue?.message}</p>
          </label>
          <label className="block">
            <textarea
              {...register("narration", {
                required: "A message is required",

                minLength: {
                  value: 11,
                  message: "Please type more than 11 characters",
                },
                maxLength: {
                  value: 200,
                  message: "You have exceeded the max number of characters",
                },
              })}
              rows={6}
              placeholder="Send your message"
              onChange={
                (e) => {
                  setValue("narration", e.target.value);
                }
                // setNarration(e.target.value)
              }
              className={cn(
                "mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:ring-0",
                {
                  "focus:ring-red-500 border-red-500 focus:border-red-500":
                    errors.narration,
                }
              )}
            ></textarea>
            <p className="text-sm text-red-500 ">{errors.narration?.message}</p>
          </label>
          {/* <label className="block">
            <input
              required
              id="password"
              name="password"
              type="password"
              {...register("password")}
              placeholder="Password"
              className={cn(
                "mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:ring-0",
                {
                  "focus:ring-red-500 border-red-500 focus:border-red-500":
                    errors.narration,
                }
              )}
            />
            {errors?.password && (
              <p className="px-1 text-xs text-red-600">
                {errors.password.message}
              </p>
            )}
          </label> */}
          <button
            disabled={isLoading}
            type="submit"
            className="bg-[#991b1b] text-white font-bold px-4 py-2 w-full uppercase rounded-md flex justify-center items-center hover:opacity-75"
          >
            {isLoading ? (
              <div className=" text-white">
                <LoadingSpinner />
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
      {isSuccess && (
        <p className="text-[#991b1b] bg-white font-bold text-center">
          <Link className="underline hover:opacity-90 text-white" to={"/"}>
            Click here to browse more salvage items.{" "}
          </Link>
        </p>
      )}
      {/* <ProfileModalForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        // profileFormEmail={profileFormEmail}
      /> */}
    </>
  );
};
