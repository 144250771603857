import React, { FC } from "react";
import { Product } from "src/types/product";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoadingSpinner } from "src/components/ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormInput } from "src/types/bid";
import cn from "clsx";
import { Link } from "react-router-dom";

//endpoint for posting create, update or delete bid item
const CreateBidItem = ({ user }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      bidValue: 0,
      narration: "",
      userId: user.id,
      userEmail: user.email,
    },
  });

  console.log("user", user);

  const fakeCreateBidItem = async (data) => {
    console.log("createbiditem", data);
    alert(JSON.stringify(data));
    return data;
  };

  const { mutate, isLoading, isError, isSuccess, error, data } =
    useMutation(fakeCreateBidItem);

  // if (data !== undefined) {
  //   alert(JSON.stringify(data));
  // }

  const onSubmit = async (data) => {
    mutate(data);
  };

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  if (error instanceof Error) {
    return <div>An error occurred: {error.message}</div>;
  }
  /**uncomment below to view input values 'onchange' in console */
  // console.log(watch("firstName"));

  return (
    <>
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8 mt-5">
        <h2 className="text-xl font-semibold">Create Your Bid</h2>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form action="#" method="POST">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="email-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <input
                      type="text"
                      name="email-address"
                      id="email-address"
                      autoComplete="email"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option>United States</option>
                      <option>Canada</option>
                      <option>Mexico</option>
                    </select>
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Street address
                    </label>
                    <input
                      type="text"
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium text-gray-700"
                    >
                      State / Province
                    </label>
                    <input
                      type="text"
                      name="region"
                      id="region"
                      autoComplete="address-level1"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      ZIP / Postal code
                    </label>
                    <input
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      autoComplete="postal-code"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-3">
            <label className="block">
              <input
                {...register("email", {
                  required: "Your email address is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                type="email"
                placeholder="Email Address"
                className={cn(
                  "mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:ring-0",
                  {
                    "focus:ring-red-500 border-red-500 focus:border-red-500":
                      errors.email,
                  }
                )}
              />
              <p className="text-sm text-red-500 ">{errors.email?.message}</p>
            </label>
            <label className="block">
              <input
                {...register("bidValue", {
                  required: "A bid value is required",
                  min: {
                    value: 1000,
                    message: "Please insert a bid value greater than 1000",
                  },
                  pattern: {
                    value: /^[1-9]+[0-9]*$/,
                    message: "invalid bid value ",
                  },
                })}
                placeholder="Bid Value"
                type="number"
                className={cn(
                  "mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:ring-0",
                  {
                    "focus:ring-red-500 border-red-500 focus:border-red-500":
                      errors.bidValue,
                  }
                )}
              />
              <p className="text-sm text-red-500 ">
                {errors.bidValue?.message}
              </p>
            </label>
            <label className="block">
              <textarea
                {...register("narration", {
                  minLength: {
                    value: 11,
                    message: "Please type more than 11 characters",
                  },
                  maxLength: {
                    value: 200,
                    message: "You have exceeded the max number of characters",
                  },
                })}
                rows={6}
                placeholder="Send your message"
                className={cn(
                  "mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:bg-white focus:ring-0",
                  {
                    "focus:ring-red-500 border-red-500 focus:border-red-500":
                      errors.narration,
                  }
                )}
              ></textarea>
              <p className="text-sm text-red-500 ">
                {errors.narration?.message}
              </p>
            </label>
            <button
              disabled={isLoading}
              type="submit"
              className="bg-[#991b1b] text-white font-bold px-4 py-2 w-full uppercase rounded-md flex justify-center items-center hover:opacity-75"
            >
              {isLoading ? (
                <div className=" text-white">
                  <LoadingSpinner />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
        <div>
          {isError && (
            <p className="font-bold bg-[#ff1a1a] text-white text-center">
              An error occurred. Please try again in a few seconds.
            </p>
          )}
          {isSuccess && (
            <p className="text-white bg-green-600 font-bold text-center">
              The Bid Item has been successfully created
            </p>
          )}
        </div>
      </div>
    </>
  );
};
export default CreateBidItem;
